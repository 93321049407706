<template>
  <multiselect
    :options="list"
    :multiple="isMultiple"
    :label="displayField"
    :track-by="valueField"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template>
  </multiselect>
</template>

<script>
import http from "../../shared/http";
import { getTranslation } from "../../shared/language-helper";
export default {
  name: "CustomDropDown",
  props: {
    displayField: { type: String, required: true },
    isMultiple: { type: Boolean, default: false },
    valueField: { type: String, required: true },
    placeholder: {
      default: "",
      type: String,
      required: false,
    },
    apiSource: { type: String, required: true },
    selectedValue: { required: true },
    includeall: { required: false, default: false },
    firstSelected: { type: Boolean, default: false },
    filter: { type: Function, default: null },
    orderBy: { type: Function, default: null },
    firstText: {
      type: String,
    },
  },
  data() {
    return {
      list: [],
      selected: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getList() {
      let path = this.apiSource;
      http.get(path).then((response) => {
        if (response) {
          var data = response;
          if (this.filter != null) {
            data = data.filter(this.filter);
          }
          if (this.includeall) {
            data.unshift({
              id: null,
            });
            data[0][this.displayField] = this.firstText;
          }
          if (this.orderBy != null) {
            this.list = data.sort(this.orderBy);
          } else {
            this.list = data;
          }

          this.selected = this.list.find((i) => i.id == null);
          this.setSelected();

          if (this.firstSelected) {
            this.selected = this.list[0];
            this.$emit("change", this.selected);
          }
        }
      });
    },
    setSelected() {
      this.selected = this.list.find((i) => i.id == this.selectedValue) || null;
    },
  },
  watch: {
    selectedValue() {
      this.setSelected();
    },
  },
};
</script>