<template>
  <div class="animated fadeIn">
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="6" md="6" xs="12" offset-sm="3">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-bars"></i>
                {{ menu.id == 0 ? $t("newMenu") : $t("updateMenu") }}</strong
              >
            </div>

            <b-row>
              <b-col sm="12">
                <b-form-group>
                  <label for="parentMenu">{{ $t("parentMenu") }}</label>
                  <CustomDropDown
                    apiSource="Menus/getlist"
                    :selectedValue="form.parentMenuId"
                    displayField="title"
                    valueField="id"
                    id="parentMenu"
                    :isMultiple="false"
                    @change="(val) => menuParentIdChanged(val)"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="menu"
                  id="title"
                  :isRequired="true"
                  @onLostFocus="onTitleLostFocus()"
                  icon="fa fa-font"
                  v-model="$v.form.title.$model"
                  :form="$v.form.title"
                />
              </b-col>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="menu"
                  id="url"
                  :isRequired="true"
                  @keydown="(e) => onUrlKeyDown(e)"
                  icon="fa fa-link"
                  v-model="$v.form.url.$model"
                  :form="$v.form.url"
                />
              </b-col>
              <b-col sm="12">
                <CheckBox
                   v-model="form.active"
                  :defaultValue="form.active"
                  :label="$t('active')"
                ></CheckBox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import http from "../../shared/http";
import stringHelper from "../../shared/string-helper";
import menuValidator from "../../validations/menu-validator";
import toast from "../../shared/toast-helper";
import BrandButton from "../../widgets/form/CustomBrandButton";
import CustomDropDown from "../../widgets/form/CustomDropDown";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import { validationMixin } from "vuelidate";
import CheckBox from "../../widgets/form/CustomCheckBox";
export default {
  name: "MenuAdd",
  components: {
    ValidationableTextBox,
    BrandButton,
    CustomDropDown,
    CheckBox
  },
  mixins: [validationMixin],
  validations: menuValidator.getValidations(),
  methods: {
    menuParentIdChanged(val) {
      if (val) this.form.parentMenuId = val.id;
      else this.form.parentMenuId = null;
    },
    onTitleLostFocus() {
      if (this.form.url == "") {
        this.form.url = stringHelper.standardUrlWithSlash(this.form.title);
      }
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey) e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumCharWithSlash(e.keyCode);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.form.parentMenuId == this.menu.id && this.menu.id != 0) {
        toast.error(this.$t("menuParentError"));
        return;
      }
      let model = { ...this.menu, ...this.form };
      if (model.parentMenuId == 0) model.parentMenuId = null;

      let path = model.id > 0 ? "Menus/update" : "Menus/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.$router.push({ path: "/menu-list" });
        }
      });
    },
    getUpdateForm(menuId) {
      let path = `Menus/getbyid?id=${menuId}`;
      http.get(path).then((menu) => {
        if (menu) {
          this.menu = menu;
          this.form.title = menu.title;
          this.form.url = menu.url;
          this.form.active = menu.active;
          this.form.parentMenuId = menu.parentMenuId;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  data() {
    return {
      form: {
        parentMenuId: 0,
        title: "",
        url: "",
        active: true,
      },
      menu: {
        id: 0,
        parentMenuId: 0,
        title: "",
        url: "",
        active: true,
      },
    };
  },
  watch: {
    form: {
      handler(obj) {
        let standardUrl = stringHelper.standardUrlWithSlash(obj.url);
        obj.url = standardUrl;
      },
      deep: true,
    },
  },
  created() {
    let menuId = this.$route.params.id ? this.$route.params.id : 0;
    if (menuId > 0) this.getUpdateForm(menuId);
  },
};
</script>
